<template>
  <div class="system-settings">
    <UiNotification v-model="showNotification" :message="message" />
    <div class="system-settings__navbar">
      <h1 class="system-settings__navbar-title">{{ $t('system-settings') }}</h1>
      <p class="system-settings__navbar-subtitle">{{ $t('interface-language') }}</p>
      <ui-menu v-model="visibleSettings">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
        </template>
        <template #body>
          <div class="sort">
            <p class="nav-bar__links-link">{{ $t('interface-language') }}</p>
          </div>
        </template>
      </ui-menu>
    </div>
    <ui-loader v-model="loader" />
    <Table
        is-code
        v-if="!loader"
        :title="$t('interface-language')"
        :actionText="$t('add-language')"
        showAction
        trash-is-hidden
        :data="getLanguages"
        :descriptionList="[{title: $t('code'), width: 80},{title: $t('interface-language'), width: 450, paddingLeft: 0},{title: $t('international-name'), width: 260, paddingLeft: 0}]"
        hideDeleteIcon
        hideEditIcon
        :showId="false"
        :internationalName="true"
        @changeRowField="changeRowField($event)"
        @addRowField="addRowField($event)"
    />
  </div>
</template>

<script>
import UiMenu from "@/components/ui/UiMenu.vue";
import Table from "@/components/ui/UiTable"
import {mapActions, mapGetters} from "vuex";
import UiNotification from "@/components/ui/UiNotification.vue";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "SystemSettings",
  components: {
    UiLoader, UiNotification,
    UiMenu,
    Table
  },
  data() {
    return {
      visibleSettings: false,
      loader: true,
      showNotification: false,
      message: ''
    }
  },

  computed: {
    ...mapGetters(['getLanguages']),
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  },

  methods: {
    ...mapActions(['languages', 'editLanguages', 'addLanguages']),
    changeRowField (data) {
      this.loader = true
      let payload = {
        name: data.name,
        internationalName: data.internationalName,
        id: data.id,
        code: data.code
      }
      this.editLanguages(payload).then(() => {
        this.languages().then(() => {
          setTimeout(() => {
            this.loader = false
            this.showNotification = true
            this.message = this.$t('changes-were-successfully-saved')
          }, 500)
        })
      })
    },

    addRowField (data) {
      this.loader = true
      let payload = {
        name: data.name,
        internationalName: data.internationalName,
        code: data.code
      }
      this.addLanguages(payload).then(() => {
        this.languages().then(() => {
          setTimeout(() => {
            this.loader = false
            this.showNotification = true
            this.message = this.$t('language-successfully-added')
          }, 500)
        })
      })
    }
  },

  mounted() {
    this.languages().then(() => {
      this.loader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.system-settings {
  padding: 150px 50px 50px 50px;
  background: #F5F5F5;
  min-height: 100vh;
  display: flex;
  gap: 50px;

  @media (max-width: 1200px) {
    padding: 104px 20px 50px 20px;
    flex-direction: column;
    gap: 30px;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    gap: 50px;

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #1B1A1F;
      white-space: nowrap;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #CE2121;
      margin-bottom: 20px;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
.nav-bar__settings {
  display: none;

  @media (max-width: 1200px) {
    display: inline-block;
  }
}
.table {
  width: 100%;
  height: fit-content;
}
.loader__backdrop {
  width: 100%;
}
</style>